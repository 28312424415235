
.box-top {
  display: flex;
  gap: 20px;

  .data-item {
    flex: 1;
    height: 240px;

    &_content {
      margin-top: 30px;
      height: 138px;
      background: #1f2a49;
      border-radius: 8px;
      box-sizing: border-box;
      padding: 20px;

      .content-top,
      .content-bottom {
        display: grid;

        .top-item,
        .bottom-item {
          display: flex;
          align-items: center;
        }

        .top-item {
          gap: 17px;

          img {
            width: 48px;
            height: 48px;
            object-fit: cover;
          }

          .item-info {
            .info-label {
              font-weight: 400;
              font-size: 14px;
              color: #7cf3ff;
              line-height: 14px;
            }

            .info-value {
              margin-top: 10px;
              font-weight: 400;
              font-size: 24px;
              color: #ffb469;
              line-height: 24px;
            }
          }
        }

        .bottom-item {
          gap: 4px;

          span {
            font-weight: 400;
            font-size: 12px;
            color: #ffffff;
            line-height: 16px;

            &.item-value {
              color: #ffb469;
            }
          }
        }
      }

      .content-split {
        height: 1px;
        background: #3f4b6a;
        border-radius: 0px 0px 0px 0px;
        margin-block: 20px 12px;
      }
    }

    &.popularity {
      .content-top,
      .content-bottom {
        grid-template-columns: repeat(4, 1fr);
      }
    }

    &.bring {
      .content-top,
      .content-bottom {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }
}
