
::v-deep .tooltip-box {
  width: 88px;
  height: 46px;
  background: rgba(24, 32, 55, 0.8);
  box-shadow: inset 0px 0px 95px 2px rgba(16, 108, 222, 0.25), inset 0px 0px 10px 2px rgba(0, 114, 255, 0.8);
  border-radius: 4px 4px 4px 4px;
  font-weight: 400;
  font-size: 20px;
  color: #ffffff;
  line-height: 46px;
  text-align: center;
  box-sizing: border-box;
}

.data-item {
  aspect-ratio: 509 / 352;

  &_content {
    margin-top: 20px;
    height: calc(100% - 36px);
  }
}
