
.data-item {
  margin-top: 20px;
  aspect-ratio: 509 / 352;

  &_content {
    margin-top: 20px;
    height: calc(100% - 36px);
    // border: 1px solid #ffffff;

    .content-top-three {
      height: 84px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 9px;

      .top-item {
        width: 84px;
        height: 84px;
        position: relative;

        img {
          position: absolute;
          width: 48px;
          height: 48px;
          object-fit: cover;
          border-radius: 50%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        .top-item-bg {
          position: absolute;
          inset: 0;
        }

        .top-item-name {
          position: absolute;
          font-weight: 400;
          font-size: 12px;
          color: #ffffff;
          top: 100%;
          left: 50%;
          transform: translate(-50%, 5px);
          text-wrap: nowrap;
        }

        &.top-one {
          width: 87px;
          transform: translateY(-21px);

          img {
            width: 56px;
            height: 56px;
          }

          .top-item-bg {
            background: url("../../../assets/image/othersLive/sales-rank-1.png");
          }
        }

        &.top-two {
          img {
            transform: translate(-50%, -30px);
          }

          .top-item-bg {
            background: url("../../../assets/image/othersLive/sales-rank-2.png");
          }
        }

        &.top-three {
          img {
            transform: translate(-50%, -30px);
          }

          .top-item-bg {
            background: url("../../../assets/image/othersLive/sales-rank-3.png");
          }
        }
      }
    }

    .content-list {
      margin-top: 40px;
      height: calc(100% - 124px);

      ::v-deep .el-scrollbar__wrap {
        min-width: 0;
        padding-inline: 0;
        margin: 0 !important;

        &::-webkit-scrollbar {
          display: none;
        }
      }

      .sales-item {
        display: flex;
        align-items: center;
        height: 30px;
        background: rgba(80, 104, 144, 0.2);
        border-radius: 4px 4px 4px 4px;
        padding-inline: 20px;

        &:not(.sales-item:first-of-type) {
          margin-top: 4px;
        }

        &_index,
        &_name,
        &_count {
          width: 33%;

          font-weight: 400;
          font-size: 12px;
          color: rgba(255, 255, 255, 0.8);
        }

        &_name {
          text-align: center;
        }

        &_count {
          text-align: right;
        }
      }
    }
  }
}
