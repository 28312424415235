
.live-video-data {
  margin-top: 20px;
  aspect-ratio: 741 / 847;
  border-radius: 8px;
  // padding: 20px;
  box-sizing: border-box;

  &_title {
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 16px;
    color: #ffffff;
    line-height: 16px;
    position: relative;

    &::before {
      content: "";
      display: inline-block;
      background: url("../../../assets/image/tag.png");
      background-repeat: no-repeat;
      width: 31px;
      height: 11px;
      margin-right: 6px;
    }

    .show-all {
      position: absolute;
      font-weight: 400;
      font-size: 16px;
      color: #81839a;
      top: 50%;
      right: 0%;
      transform: translateY(-50%);
      cursor: pointer;
    }
  }

  &_content {
    margin-top: 20px;
    height: calc(100% - 36px);
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    .video-item {
      flex-shrink: 0;
      width: calc((100% - 40px) / 3);
      height: calc((100% - 20px) / 2);
      border-radius: 8px;
      background-color: #0c0e3f;
      display: flex;
      flex-direction: column;
      gap: 10px;

      &_play {
        background-color: #ffffff27;
        height: calc(100% - 69px);
        border-radius: 8px;
        overflow: hidden;
        position: relative;
        cursor: pointer;

        .play-status {
          position: absolute;
          top: 0;
          right: 0;
          padding: 2px 6px;
          font-weight: 400;
          font-size: 12px;
          color: #ffffff;
          z-index: 999;

          .iconfont {
            font-size: 12px;
            margin-right: 2px;
          }

          &.pending,
          &.pause {
            background-color: #4741ff99;
          }

          &.no-aired,
          &.no-start,
          &.disconnect {
            background-color: #e2e2e299;
          }

          &.over {
            background-color: #ff414498;
          }
        }

        video {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        ::v-deep .video-js {
          width: 100%;
          height: 100%;
        }
      }

      &_title {
        padding-inline: 10px;
        height: 19px;
        font-weight: 400;
        font-size: 14px;
        color: #ffffff;
        white-space: nowrap; /* 防止文本换行 */
        overflow: hidden; /* 隐藏超出容器的部分 */
        text-overflow: ellipsis; /* 超出部分显示省略号 */
      }

      &_user {
        height: 20px;
        font-weight: 400;
        font-size: 12px;
        color: #ffffff;
        padding-inline: 10px;

        display: flex;
        align-items: center;
        justify-content: space-between;

        .user-info,
        .user-hot {
          display: flex;
          align-items: center;
          gap: 4px;
        }

        .user-info {
          width: 60%;

          img {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            object-fit: cover;
          }

          .user-info-name {
            width: calc(100% - 24px);
            white-space: nowrap; /* 防止文本换行 */
            overflow: hidden; /* 隐藏超出容器的部分 */
            text-overflow: ellipsis; /* 超出部分显示省略号 */
          }
        }

        .user-hot {
          .iconfont {
            color: #19fdff;
          }
        }
      }
    }
  }
}
