
.live-data-screen {
  background-color: #080b32;
  height: 100vh;
  // overflow-x: auto;

  ::v-deep .el-scrollbar__wrap {
    min-width: 1412px;
    padding-inline: 40px;
    margin: 0 !important;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &_header {
    padding-top: 10px;
    margin-bottom: 40px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 800;
    font-size: 30px;
    color: #ffffff;
    line-height: 30px;
    text-align: center;
  }

  &_box {
    ::v-deep .data-item {
      background-color: #0c0e3f;
      border-radius: 8px;
      padding: 20px;
      box-sizing: border-box;

      &_title {
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 16px;
        color: #ffffff;
        line-height: 16px;

        &::before {
          content: "";
          display: inline-block;
          background: url("../../assets/image/tag.png");
          background-repeat: no-repeat;
          width: 31px;
          height: 11px;
          margin-right: 6px;
        }
      }
    }

    .box-bottom {
      display: flex;
      gap: 30px;
      margin-top: 20px;
      padding-bottom: 10px;

      &_left,
      &_right {
        width: 30%;
        flex-shrink: 0;
        flex-grow: 0;
      }

      &_center {
        width: 40%;
      }
    }
  }
}
