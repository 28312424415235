
::v-deep .tooltip-box {
  width: 151px;
  // height: 135px;
  background: rgba(24, 32, 55, 0.8);
  box-shadow: inset 0px 0px 95px 2px rgba(16, 108, 222, 0.25), inset 0px 0px 10px 2px rgba(0, 114, 255, 0.8);
  border-radius: 4px 4px 4px 4px;
  box-sizing: border-box;
  padding: 10px;

  .tooltip-box_user {
    font-weight: 500;
    font-size: 12px;
    color: #ffffff;
  }

  .tooltip-box_list {
    .tooltip-item {
      display: flex;
      justify-content: space-between;
      align-items: center;

      & + .tooltip-item {
        margin-top: 2px;
      }

      .item-label {
        display: flex;
        align-items: center;
        gap: 4px;

        i {
          display: block;
          width: 8px;
          height: 4px;
          border-radius: 2px 2px 2px 2px;
        }

        span {
          font-weight: 400;
          font-size: 12px;
          color: #ffffff;
        }
      }

      .item-value {
        font-weight: 400;
        font-size: 12px;
        color: #ffffff;
      }
    }
  }
}

.data-item {
  margin-top: 20px;
  aspect-ratio: 509 / 352;

  &_content {
    margin-top: 20px;
    height: calc(100% - 36px);
  }
}
