
.live-sales-data {
  aspect-ratio: 741 / 436;
  background: #0c0e3f;
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .data-title {
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 32px;
    color: #ffffff;
    line-height: 38px;
    margin-bottom: 32px;
  }

  .data-value {
    font-weight: 400;
    font-size: 96px;
    line-height: 113px;
    background: linear-gradient(180deg, #0068d3 0%, #3febff 50%, #0069d3 100%);
    margin-bottom: 37px;
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
  }

  .data-sales {
    width: 100%;
    height: 43px;
    background: linear-gradient(90deg, rgba(56, 65, 255, 0) 0%, rgba(56, 65, 255, 0.8) 50%, rgba(56, 65, 255, 0) 100%);
    display: flex;
    align-items: center;
    justify-content: center;

    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 16px;
    color: #ffffff;
    position: relative;

    span {
      font-size: 13px;
      margin-right: 10px;
    }

    &::before,
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      left: 0;
      height: 1px;
      background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #3197ff 50%, rgba(255, 255, 255, 0) 100%);
    }

    &::before {
      top: 0;
    }

    &::after {
      bottom: 0;
    }
  }
}
