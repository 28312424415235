
.data-item {
  margin-top: 20px;
  aspect-ratio: 509 / 724;

  &_content {
    margin-top: 20px;
    height: calc(100% - 36px);
    width: 100%;

    .flex {
      display: flex;
      align-items: center;
      padding-inline: 12px;
      gap: 10px;

      .flex-item {
        flex: 1;

        &.name {
          flex: 2;
        }
      }
    }

    .content-th {
      height: 40px;
      background: rgba(129, 178, 255, 0.2);
      border-radius: 4px;

      .flex-item {
        font-weight: 400;
        font-size: 14px;
        color: #ffffff;
        text-wrap: nowrap;
      }
    }

    .content-tb {
      height: calc(100% - 42px);
      width: 100%;

      ::v-deep .el-scrollbar__wrap {
        min-width: 0;
        padding-inline: 0;
        margin: 0 !important;

        &::-webkit-scrollbar {
          display: none;
        }
      }

      .goods-empty {
        text-align: center;
        padding-top: 150px;
        font-weight: 400;
        font-size: 14px;
        color: #ffffff7e;
        line-height: 16px;
      }

      .goods-item {
        height: 40px;
        background: rgba(80, 104, 144, 0.2);
        border-radius: 4px;
        margin-top: 4px;
        font-size: 12px;

        .flex-item {
          color: rgba(255, 255, 255, 0.8);

          &.name {
            display: flex;
            align-items: center;
            gap: 4px;
            font-size: 11px;

            img {
              width: 30px;
              height: 30px;
              object-fit: cover;
              border-radius: 4px;
            }

            .name-label {
              display: -webkit-box; /* 使用 flexbox 布局来设置多行 */
              -webkit-box-orient: vertical; /* 设置布局的方向为垂直方向 */
              overflow: hidden; /* 隐藏溢出的内容 */
              -webkit-line-clamp: 2; /* 限制显示的行数为 2 */
              text-overflow: ellipsis; /* 超出文本显示省略号 */
              white-space: normal;
            }
          }
        }
      }
    }
  }
}
